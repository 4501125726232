<template>
  <div class="manage-manager" :class="{skeleton: !state.loaded}">
    <div class="card" v-if="$route.params.projectType === 'invest'">
      <div class="card-header">
        <span>기업 프로필</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm">
                <span>기업명</span>
              </label>
              <input type="text" class="form-control border-focus-point font-sm" :value="state.form.corporateName" disabled/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm">
                <span>대표자명</span>
              </label>
              <input type="text" class="form-control border-focus-point font-sm" :value="state.form.ceoName" disabled/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm">
                <span>사업자등록번호</span>
              </label>
              <input type="text" class="form-control border-focus-point font-sm" :value="state.form.businessNum" disabled/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm">
                <span>법인등록번호</span>
              </label>
              <input type="text" class="form-control border-focus-point font-sm" :value="state.form.corporateNum" disabled/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}CorpEstablished`">
                <span class="color-point">*</span>
                <span> 설립일</span>
              </label>
              <Number :id="`${component.name}CorpEstablished`" :value.sync="state.form.corpEstablished" :allowZero="true" :noComma="true" placeholder="ex) 20220707" :maxlength="8" :enter="submit"/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}BusinessTel`">
                <span class="color-point">*</span>
                <span> 기업 전화번호</span>
              </label>
              <Phone :id="`${component.name}BusinessTel`" placeholder="ex) 021234567" :value.sync="state.form.businessTel" :enter="submit"/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}BusinessAddress`">
                <span class="color-point">*</span>
                <span> 기업 주소</span>
              </label>
              <Address :id="`${component.name}BusinessAddress`" :zoneCode.sync="state.form.businessPost" :address1.sync="state.form.businessAddr1" :address2.sync="state.form.businessAddr2" :enter="submit"/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}CorpType`">
                <span class="color-point">*</span>
                <span> 기업 업종</span>
              </label>
              <select :id="`${component.name}CorpType`" class="form-control border-focus-point font-sm" v-model="state.form.corpType">
                <option :value="null">업종을 선택해주세요.</option>
                <option v-for="(t, idx) in $definitions.codes.arr.corpType" :key="idx" :value="t.key">{{ t.value }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-0">
              <label class="subject font-sm" :for="`${component.name}ManagerAddress`">
                <span>소개</span>
              </label>
              <textarea class="form-control border-focus-point font-sm" v-model="state.form.introduction"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card" v-if="$route.params.projectType === 'invest'">
          <div class="card-header">
            <span>담당자 프로필</span>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label :for="`${component.name}ManagerName`" class="subject font-sm">
                <span class="color-point">*</span>
                <span> 담당자명</span>
              </label>
              <input :id="`${component.name}ManagerName`" type="text" class="form-control border-focus-point font-sm" v-model="state.form.managerName" @keyup.enter="submit()"/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}ManagerEmail`">
                <span class="color-point">*</span>
                <span> 이메일</span>
              </label>
              <input :id="`${component.name}ManagerEmail`" type="email" class="form-control border-focus-point font-sm" placeholder="ex) example@domain.com" v-model="state.form.managerEmail" @keyup.enter="submit()"/>
            </div>
            <div class="form-group mb-0">
              <label class="subject font-sm" :for="`${component.name}ManagerHp`">
                <span class="color-point">*</span>
                <span> 휴대전화</span>
              </label>
              <Phone :id="`${component.name}ManagerHp`" placeholder="ex) 01012345678" :value.sync="state.form.managerHp" :enter="submit"/>
            </div>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-header">
            <span>진행자 프로필</span>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label :for="`${component.name}ManagerName`" class="subject font-sm">
                <span>진행자명(기업명)</span>
              </label>
              <input :id="`${component.name}ManagerName`" type="text" class="form-control border-focus-point font-sm" v-model="state.form.managerName" disabled/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}ManagerMail`">
                <span class="color-point">*</span>
                <span> 이메일</span>
              </label>
              <input :id="`${component.name}ManagerMail`" type="email" class="form-control border-focus-point font-sm" placeholder="ex) example@domain.com" v-model="state.form.managerMail" @keyup.enter="submit()"/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}ManagerHp`">
                <span class="color-point">*</span>
                <span> 휴대전화</span>
              </label>
              <Phone :id="`${component.name}ManagerHp`" placeholder="ex) 01012345678" :value.sync="state.form.managerHp" :enter="submit"/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}ManagerAddress`">
                <span class="color-point">*</span>
                <span> 주소</span>
              </label>
              <Address :id="`${component.name}ManagerAddress`" :zoneCode.sync="state.form.managerPost" :address1.sync="state.form.managerAddr1" :address2.sync="state.form.managerAddr2" :enter="submit"/>
            </div>
            <div class="form-group mb-0">
              <label class="subject font-sm" :for="`${component.name}ManagerAddress`">
                <span>소개</span>
              </label>
              <textarea class="form-control border-focus-point font-sm" v-model="state.form.introduction"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <span>SNS 정보</span>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label :for="`${component.name}HomeUrl`" class="subject font-sm">
                <span>홈페이지</span>
              </label>
              <input :id="`${component.name}HomeUrl`" type="url" class="form-control border-focus-point font-sm" placeholder="ex) ohmycompany.com" v-model="state.form.homeUrl" @keyup.enter="submit()"/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}Facebook`">
                <span>페이스북</span>
              </label>
              <input :id="`${component.name}Facebook`" type="url" class="form-control border-focus-point font-sm" placeholder="ex) facebook.com/omc2012" v-model="state.form.facebook" @keyup.enter="submit()"/>
            </div>
            <div class="form-group">
              <label class="subject font-sm" :for="`${component.name}Blog`">
                <span>블로그</span>
              </label>
              <input :id="`${component.name}Blog`" type="url" class="form-control border-focus-point font-sm" placeholder="ex) blog.naver.com/omcofficial" v-model="state.form.blog" @keyup.enter="submit()"/>
            </div>
            <div class="form-group mb-0">
              <label class="subject font-sm" :for="`${component.name}Insta`">
                <span>인스타그램</span>
              </label>
              <input :id="`${component.name}Insta`" type="tel" class="form-control border-focus-point font-sm" placeholder="ex) instagram.com/oh_mycompany" v-model="state.form.insta" @keyup.enter="submit()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action" v-if="$route.params.projectType === 'invest' ? project.memberSeq === $store.state.account.memberSeq : project.openId === $store.state.account.memberSeq">
      <button class="btn btn-block btn-point font-sm" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Date from "@/components/Date";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";
import Number from "@/components/Number";

function Component(initialize) {
  this.name = "pageMyPageAccount";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number, Date, Phone, Address}, props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      form: {
        blog: "",
        facebook: "",
        homeUrl: "",
        insta: "",
        managerAddr1: "",
        managerAddr2: "",
        managerHp: "",
        managerMail: "",
        managerPost: "",
        memberSeq: "",
        projectSeq: "",
      }
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const submit = () => {
      const args = lib.getRenewed(state.form);

      if (projectType === "invest") {
        if (!args.corpEstablished?.trim()) {
          document.getElementById(`${component.name}CorpEstablished`)?.focus();
          return store.commit("setSwingMessage", "설립일을 입력해주세요.");
        } else if (!args.businessTel?.trim()) {
          document.getElementById(`${component.name}BusinessTel`)?.focus();
          return store.commit("setSwingMessage", "기업 전화번호를 입력해주세요.");
        } else if (!args.businessPost?.trim() || !args.businessAddr1?.trim() || !args.businessAddr2?.trim()) {
          document.getElementById(`${component.name}BusinessAddress`)?.focus();
          return store.commit("setSwingMessage", "기업 주소를 입력해주세요.");
        } else if (!args.corpType) {
          document.getElementById(`${component.name}CorpType`)?.focus();
          return store.commit("setSwingMessage", "기업 업종을 선택해주세요.");
        } else if (!args.managerName?.trim()) {
          document.getElementById(`${component.name}ManagerName`)?.focus();
          return store.commit("setSwingMessage", "담당자명을 입력해주세요.");
        } else if (!args.managerEmail?.trim()) {
          document.getElementById(`${component.name}ManagerEmail`)?.focus();
          return store.commit("setSwingMessage", "담당자 이메일을 입력해주세요.");
        } else if (!args.managerHp?.trim()) {
          document.getElementById(`${component.name}ManagerHp`)?.focus();
          return store.commit("setSwingMessage", "담당자 휴대전화를 입력해주세요.");
        }
      } else {
        if (!args.managerMail?.trim()) {
          document.getElementById(`${component.name}ManagerMail`)?.focus();
          return store.commit("setSwingMessage", "이메일을 입력해주세요.");
        } else if (!args.managerHp?.trim()) {
          document.getElementById(`${component.name}ManagerHp`)?.focus();
          return store.commit("setSwingMessage", "휴대전화 번호를 입력해주세요.");
        } else if (!lib.isValidMobileNum(args.managerHp?.trim())) {
          document.getElementById(`${component.name}ManagerHp`)?.focus();
          return store.commit("setSwingMessage", "휴대전화 번호를 정확하게 입력해주세요.");
        } else if (!args.managerPost?.trim() || !args.managerAddr1?.trim() || !args.managerAddr2?.trim()) {
          document.getElementById(`${component.name}ManagerAddress`)?.focus();
          return store.commit("setSwingMessage", "주소를 입력해주세요.");
        }
      }

      state.loaded = false;
      http.put(`/api/maker/project/${projectType}/${projectSeq}/maker`, args).then(() => {
        state.loaded = true;
        load();
        store.commit("setSwingMessage", "진행자 정보를 저장하였습니다.");
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const load = () => {
      state.loaded = false;
      http.get(`/api/maker/${projectType}/${projectSeq}/maker`).then(({data}) => {
        state.loaded = true;
        state.form = data.body;
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    return {component, state, submit};
  }
});
</script>

<style lang="scss" scoped>
.manage-manager {
  .card {
    box-shadow: none;
    border: $px1 solid #eee;

    .card-header {
      background: #f8f9fe;
    }
  }

  .action {
    max-width: $px337;
    margin: 0 auto;

    .btn {
      padding: $px15 0;
    }
  }

  &.skeleton {
    .card {
      .card-header > span {
        @include skeleton;
      }

      .card-body {
        .form-group {
          > label > span, input, select, textarea {
            @include skeleton;
          }

          &::v-deep .address {
            .btn, input, span {
              @include skeleton;
            }
          }
        }
      }
    }

    .action .btn {
      @include skeleton;
    }
  }
}
</style>